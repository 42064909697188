.blog-page {
  position: relative;
  width: 100%;
  height: 100vh; // Fyller hele skjermen vertikalt
  margin: 2rem 0; // Gir litt plass over og under kortene
  object {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; // Setter bildet bak kortet
  }
}

.blog-card {
  position: relative;
  top: 50%; // Sentrerer kortet vertikalt
  left: 50%; // Sentrerer kortet horisontalt
  transform: translate(-50%, -50%); // Nøyaktig sentrering
  max-width: 900px;
  width: 90%; // Gjør kortet responsivt, tilpasser seg skjermstørrelsen med en padding
  background-color: #ffffff; // Bakgrunnsfarge for kortet
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  z-index: 1; // Sørger for at kortet er over bakgrunnsbildet
  border-radius: 22px;
  img {
    width: 100%;
    max-height: 300px; // Begrenser høyden på bildet
    border-radius: 22px; // Runde hjørner for bildet
    object-fit: cover; // Skalerer bildet for å fylle hele rammen
  }
  .close-button {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    cursor: pointer;
    font-size: 1.5rem; // Størrelsen på "X"-tegnet
    color: #ffffff; // Velg en farge som matcher designet ditt
    z-index: 2; // Sørger for at knappen er klikkbar over andre elementer
    border-radius: 22px;
    background-color: #003366; // Bakgrunnsfarge for knappen
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    padding: 0 0.5rem;
    &:hover {
      transform: translateY(-2px);
      color: #1e90ff; // Endre farge ved hover for å indikere at knappen er interaktiv
    }
  }
  .blog-title {
    font-size: 1.7rem; /* En passende skriftstørrelse for tittelen */
    color: #003366; /* En sterk farge for tittelen */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .blog-content {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.17rem; /* En lesbar skriftstørrelse for beskrivelsen */
    color: #003366; /* En myk farge for teksten */
  }
}

@media (max-width: 768px) {
  .blog-card {
    margin-top: 1rem; // Øker marginen for bedre lesbarhet
    padding: 1rem; // Reduserer padding for bedre lesbarhet
    .blog-title {
      font-size: 1.3rem; // Reduserer skriftstørrelsen for tittelen
    }
    .blog-content {
      font-size: 1rem; // Reduserer skriftstørrelsen for teksten
    }
  }
}
@media (max-width: 480px) {
  .blog-card {
    .blog-title {
      font-size: 1.2rem; // Reduserer skriftstørrelsen for tittelen på mindre skjermer
    }
    .blog-content {
      font-size: 0.9rem; // Reduserer skriftstørrelsen for teksten
    }
  }
}
