.unique-services-section {
  background-color: #f8f9fa; /* En nøytral bakgrunnsfarge */
  padding: 60px 0; /* Vertikal padding */
  text-align: center; /* Sentrerer innholdet */
  z-index: 1; /* Sørger for at seksjonen er foran bakgrunnsanimasjonen */
}
.services-content {
  max-width: 1200px; /* Begrenser maksimal bredde for bedre lesbarhet */
  margin: 0 auto; /* Sentrerer containeren i banneret */
  padding: 0 20px; /* Padding på sidene for å unngå at innholdet berører kantene */
}
.content-part {
  margin: 0 auto;
  text-align: left;
  max-width: 670px;
}
.unique-services-title {
  font-size: 1.7rem; /* En passende skriftstørrelse for tittelen */
  color: #003366; /* En sterk farge for tittelen */
  margin-bottom: 25px; /* Mellomrom mellom tittelen og beskrivelsen */
}

.unique-services-description {
  font-size: 1.17rem; /* En lesbar skriftstørrelse for beskrivelsen */
  color: #003366; /* En myk farge for teksten */
  margin: auto; /* Sentrerer beskrivelsen */
  padding: 0 15x; /* Legger til padding på sidene for små skjermer */
}
.services-images {
  margin-top: 4rem;
  width: 100%; /* Sørger for at elementene kan pakke på små skjermer */
  .slick-slide {
    display: flex;
    justify-content: center; // Sentrerer kortene innenfor hver slide
  }
}

.services-image {
  margin: 10px; /* Gir litt mellomrom mellom bildene */
  text-align: center; /* Sentrerer SVG-objektet */
  object {
    max-width: 100%; /* Sørger for at SVG-objektet ikke overstiger containeren */
    height: auto; /* Be varer bildets aspektforhold */
  }
}

@media (max-width: 768px) {
  .services-images {
    flex-direction: column;
    align-items: center; /* Sentrerer karusellbildet på små skjermer */
  }

  .services-image {
    flex-basis: 100%;
    max-width: 100%; /* Lar karusellbildet ta full bredde på små skjermer */
  }
}

/* Medieforespørsler for mindre skjermer */
@media (max-width: 768px) {
  .unique-services-title {
    font-size: 1.3rem; /* Reduserer skriftstørrelsen for tittelen på mindre skjermer */
  }

  .unique-services-description {
    font-size: 1rem; /* Justerer skriftstørrelsen for beskrivelsen */
    max-width: 100%; /* Tillater beskrivelsen å fylle tilgjengelig bredde */
  }
}

@media (max-width: 480px) {
  .unique-services-title {
    font-size: 1.2rem; /* Ytterligere reduksjon av skriftstørrelsen for tittelen */
  }

  .unique-services-description {
    font-size: 1rem; /* Mindre skriftstørrelse for beskrivelsen for bedre lesbarhet */
  }
}
