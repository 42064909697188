.add-edit-category {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Bakgrunnsfarge for overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-edit-card-category {
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05); /* Skyggeeffekt for kortet */
  max-width: 800px; /* Juster bredden etter behov */
  width: 100%;
  padding: 20px;
  .card-header-category {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #003366;
    font-size: 1.5rem;
    font-weight: 600;
    i {
      cursor: pointer;
      margin-top: -40px;
      margin-right: -20px;
      background: #f0f0f0f0;
      padding: 5px 10px;
      border-radius: 0 22px 0 0;
    }
    i:hover {
      color: #1e90ff;
    }
    .head {
      margin-left: 1rem;
    }
  }
}

.category-table-container {
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      background-color: #fcfafa;
      color: #003366;
      font-weight: 500;
      padding: 10px;
    }

    tr:nth-child(even) {
      background-color: #fcfafa;
    }
    tr:hover {
      background-color: #fffff0;
    }
    i {
      cursor: pointer;
      margin-right: 0.5rem;
      &:hover {
        color: #1e90ff;
      }
    }
    input {
      width: 100%;
      margin: 0;
    }
  }
}
.form-category {
  display: flex;
  align-items: center;
  input {
    padding: 11px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 22px; // Avrundede hjørner for input-feltene
    font-size: 12px;
    font-size: normal;
    color: #003366; // Tekstfarge for input-feltene
    &:focus {
      border-color: #007bff; // Endrer border-farge ved fokus
      outline: none; // Fjerner standard outline
    }
    &:hover {
      border-color: #007bff; // Endrer border-farge ved hover
    }
  }
  button {
    margin-left: 10px;
    margin-bottom: 0;
    padding: 8px 30px;
  }
}
