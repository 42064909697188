.background {
  overflow: hidden;
  display: flex;
  height: 100vh;
  z-index: -1;
  position: absolute;
}

.box {
  width: 140vmin;
  height: 140vmin;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.42);
  }
}

.small-box {
  width: 70vmin;
  height: 70vmin;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(1.65);
  }
}
.spin-container {
  width: 100%;
  height: 100%;
  animation: spin 12s ease-in-out infinite alternate;
  position: relative;
}
.small-spin-container {
  width: 100%;
  height: 100%;
  animation: spin 12s ease-in-out infinite alternate;
  position: relative;
}
.shape {
  width: 100%;
  height: 100%;
  transition: border-radius 1s ease-out;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morph 8s ease-in-out infinite both alternate;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  background: #fafbfc;
}
.small-shape {
  width: 100%;
  height: 100%;
  transition: border-radius 1s ease-out;
  border-radius: 0% 100% 65% 35% / 1% 12% 88% 99%;
  animation: morph 8s ease-in-out infinite both alternate;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  background: #f5f9fd;
}
@keyframes morph {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  100% {
    border-radius: 40% 70%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
