.header {
  background-color: #fff;
  color: white;
  padding: 10px 0;
}

.header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header__logo img {
  width: 220px;
}

.header__nav {
  z-index: 1000;
  font-size: 1.1rem;
  .nav-links {
    .active-link {
      border-bottom: 2px solid #1e90ff;
    }
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    li {
      margin-left: 20px;

      a {
        color: #003366;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #1e90ff;
        }
      }
    }
  }
}
.contact-button a {
  background-color: #1e90ff; // Button fill color
  color: #fff !important; // Button text color
  padding: 10px 30px;
  border-radius: 22px; // Increase border-radius for a round effect
  text-decoration: none; // Remove underline from the link
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: 1px solid #1e90ff; // Add a border to the button

  &:hover {
    background-color: #fff; // Darken the button on hover for a subtle effect
    color: #1e90ff !important;
    transform: scale(1.05); // Slightly enlarge the button on hover
    transform: translateY(-2px);
  }
}
.header__toggle {
  display: none;
  cursor: pointer;
  background: none;
  border: none;

  span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #1e90ff;
    margin: 4px 0;
    transition: background-color 0.3s ease;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px 0;
  }
  .header__nav {
    background-color: #fff;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Cover entire height from top */
    left: 0;
    right: 0;
    bottom: 0; /* Cover entire height from bottom */
    width: 100%;
    height: 200px; /* Adjust to cover the entire page height */
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  .active-link {
    border-bottom: none !important;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    li {
      margin: 10px 0;
    }
    .contact-button {
      margin-top: 20px;
    }
  }

  .header__nav--visible {
    display: flex;
  }

  .header__toggle {
    display: block;
  }

  .header__toggle.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-6px, 4px);
  }

  .header__toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .header__toggle.open span:nth-child(3) {
    transform: rotate(45deg) translate(-6px, -4px);
  }
}
