/* AddEditPartner.scss */

.add-edit-project {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Bakgrunnsfarge for overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-edit-card {
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05); /* Skyggeeffekt for kortet */
  max-width: 800px; /* Juster bredden etter behov */
  width: 100%;
  padding: 20px;
  .card-header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #003366;
    font-size: 1.5rem;
    font-weight: 600;
    i {
      cursor: pointer;
      margin-top: -40px;
      margin-right: -20px;
      background: #f0f0f0f0;
      padding: 5px 10px;
      border-radius: 0 22px 0 0;
    }
    i:hover {
      color: #1e90ff;
    }
    div {
      margin-left: 1rem;
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"] {
    width: 90%;
    padding: 10px;
    margin: 8px;
    border: 1px solid #ccc;
    border-radius: 22px;
    font-size: 12px;
    font-size: normal;
    color: #003366;
    &:focus {
      border-color: #007bff;
      outline: none;
    }
    &:hover {
      border-color: #007bff;
    }
  }
  .contact-part {
    margin-top: 2rem;
    color: #003366;
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 1rem;
  }
  .checkboxes {
    margin-left: 0.5rem;
    margin-top: 1rem;
    .checkbox {
      display: flex;
      align-items: center;

      input {
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        margin-right: 0.5rem;
        border-radius: 22px;
        border: 1px solid #ccc;
        cursor: pointer;
        &:checked {
          background-color: #007bff;
        }
        &:hover {
          border-color: #007bff;
        }
      }
    }
  }
  .project-contact {
    margin-top: 1rem;
    label {
      margin-left: 1rem;
    }
    .select-options {
      .css-13cymwt-control,
      .css-t3ipsp-control {
        font-size: 12px; // Mindre font-størrelse for Select-komponenten
        font-weight: normal;
        color: #003366; // Tekstfarge for Select-komponenten
        font: -webkit-control;
        border-radius: 22px; // Avrundede hjørner for Select-komponenten
        border: 1px solid #ccc;
        margin: 0.1rem 9px;
        padding: 0;

        &:hover {
          border-color: #007bff; // Endrer border-farge ved hover
        }
      }
      .css-1dimb5e-singleValue {
        font-size: 12px; // Mindre font-størrelse for Select-komponenten
        font-weight: normal;
        color: #003366; // Tekstfarge for Select-komponenten
        font: -webkit-control;
      }
      .css-1nmdiq5-menu {
        font-size: 12px; // Mindre font-størrelse for menyen
        font-weight: normal;
        font: -webkit-control;
        color: #003366; // Tekstfarge for menyen
      }
    }
  }
  .save-btn {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }

  .form-divide {
    color: #003366;

    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    .input-field {
      width: 50%;
      margin-top: 0.5rem;
      label {
        margin-left: 1rem;
      }
      input {
        margin-top: 0.1rem;
        margin-bottom: 0;
        padding: 11px;
      }

      .select-options {
        .css-13cymwt-control,
        .css-t3ipsp-control {
          font-size: 12px; // Mindre font-størrelse for Select-komponenten
          font-weight: normal;
          color: #003366; // Tekstfarge for Select-komponenten
          font: -webkit-control;
          border-radius: 22px; // Avrundede hjørner for Select-komponenten
          border: 1px solid #ccc;
          margin: 0.1rem 9px;
          padding: 0;

          &:hover {
            border-color: #007bff; // Endrer border-farge ved hover
          }
        }
        .css-1dimb5e-singleValue {
          font-size: 12px; // Mindre font-størrelse for Select-komponenten
          font-weight: normal;
          color: #003366; // Tekstfarge for Select-komponenten
          font: -webkit-control;
        }
        .css-1nmdiq5-menu {
          font-size: 12px; // Mindre font-størrelse for menyen
          font-weight: normal;
          font: -webkit-control;
          color: #003366; // Tekstfarge for menyen
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .add-edit-card {
    max-width: 90%;
  }
}
