.portal-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(circle, #ab9bfb, #d8f9fb);
  transition: background 0.5s ease;
}

.portal-login-container {
  width: 400px;
}

.card-login {
  background: #fff;
  border-radius: 22px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 60px;
}

.portal-login-title {
  font-size: 1.1rem;
  color: #003366; /* En myk farge for teksten */
  margin-bottom: 25px;
  text-align: center;
}
.portal-login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type="email"],
  input[type="password"] {
    width: 90%;
    padding: 10px;
    margin: 8px;
    border: 1px solid #ccc;
    border-radius: 22px;
    font-size: 12px;
    font-size: normal;
    color: #003366;
    &:focus {
      border-color: #007bff;
      outline: none;
    }
    &:hover {
      border-color: #007bff;
    }
  }
  input[type="password"] {
    margin-bottom: 2rem;
  }
}
